import React, { useState, useEffect } from "react";
import "../../Style.css";
import Utils from "../../core/Utils";
import UserHelper from "../../core/UserHelper";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connectToStore } from "../../data/store";
import SideBarMenuItems from "../SideBarMenuItems";
import SettingsMenuItems from "../SettingsMenuItems";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import {
  SideBarMenuNames,
  SettingsMenuList,
  MaxDateLimit,
} from "../../core/Constants";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MessagePopup from "../common/MessagePopUp";
import { Typography } from "@mui/material";
import { Grid, Box, Container, Stack, Switch } from "@mui/material";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Loader from "../common/Loader";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { getBusiness } from "../../data/localSettingsActions";
import { postAsync } from "../../core/serviceClient";
import { UserRoles } from "../../core/Constants";
import { LoginPage, ProfitAndLossReportPage } from "../../core/PageConstants";
import CountryHelper from "../../core/CountryHelper";

const ProfitAndLossReport = (props) => {
  document.title = "Profit And Loss";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [filterByBranch, setFilterByBranch] = useState(false);
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);

  const [localSettings, setLocalSettings] = useState(connectToStore());
  const [reportContent, setReportContent] = useState("");
  const [emailId, setEmailId] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branches, setBranches] = useState([]);

  const handleFilterByBranchChange = (event) => {
    setFilterByBranch(event.target.checked);
  };

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const onDateChange = (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      let dateDiff = dateArray[1].diff(dateArray[0], "days");
      if (dateDiff > MaxDateLimit) {
        setMsgText(
          "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
        );

        setMsgOpen(true);
      } else {
        setSelectedDate(dateArray);
      }
    }
  };

  const checkAuthentication = () => {
    if (
      UserHelper.CheckPermission(
        ProfitAndLossReportPage,
        navigate,
        localSettings
      )
    ) {
      const business = getBusiness();
      if (
        !Utils.IsNullOrEmptyObject(business) &&
        !Utils.IsNullOrEmptyArray(business.branchLocations)
      ) {
        setBranches(business.branchLocations);
      }
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  const handleSelect = (event) => {
    setSelectedBranch(event.target.value);
  };

  const handleSubmit = async () => {
    const branchId =
      filterByBranch && selectedBranch !== null ? selectedBranch : "";
    const Admin =
      localSettings.user.role === UserRoles.GlobalAdmin && !filterByBranch;
    const currentDate = Utils.GetCurrentDate();
    setIsLoading(true);
    let response = await postAsync("Reports/ProfitandLossReport", {
      BusinessId: localSettings.business.id,
      CompanyName: localSettings.business.companyName,
      EmailId: emailId,
      startDate: selectedDate[0],
      endDate: selectedDate[1],
      branchId: branchId,
      UserProfile: localSettings.user,
      IsAdmin: Admin,
      LocalDate: Utils.GetISODateTimeString(currentDate),
      IsSendEmail: true,
      Token: localSettings.token,
    });
    setReportContent(response.data.webContent);
    setIsLoading(false);
  };

  const handleEmailClicked = async () => {
    const branchId =
      filterByBranch && selectedBranch !== null ? selectedBranch : "";
    const Admin =
      localSettings.user.role === UserRoles.GlobalAdmin && !filterByBranch;
    const currentDate = Utils.GetCurrentDate();
    setIsLoading(true);
    let dateDiff = selectedDate[1].diff(selectedDate[0], "days");
    if (dateDiff >= 365) {
      setIsLoading(false);
      setMsgText("The date range should be less than or equal to 365 days.");
      setMsgOpen(true);
      return false;
    } else if (!Utils.IsEmailValid(emailId)) {
      setIsLoading(false);
      setMsgText("Please enter a valid email ID.");
      setMsgOpen(true);
      return false;
    } else {
      let response = await postAsync("Reports/ProfitandLossReport", {
        BusinessId: localSettings.business.id,
        CompanyName: localSettings.business.companyName,
        EmailId: emailId,
        startDate: selectedDate[0],
        endDate: selectedDate[1],
        branchId: branchId,
        UserProfile: localSettings.user,
        IsAdmin: Admin,
        LocalDate: Utils.GetISODateTimeString(currentDate),
        IsSendEmail: true,
        Token: localSettings.token,
      });
      setIsNavigateToLogin(response.isNavigateToLogin);
      let errorMsg = "Unable to process your request.";
      if (response.error) {
        errorMsg = response.errorMessage;
      } else if (response.data.emailSent) {
        errorMsg = "Email sent successfully.";
      }
      setMsgText(errorMsg);
      setMsgOpen(true);
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />

      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Settings} />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" className="">
              <SettingsMenuItems
                selectedMenu={SettingsMenuList.BusinessReports.ProfitAndLoss}
              />
              <Grid flex="1" spacing={2} className="form-sec">
                <form container spacing={2} onSubmit={handleSubmit}>
                  <Grid container className="title-sec title-xs">
                    <Grid xs={12} md={4} lg={4}>
                      <Typography
                        level="h1"
                        component="h2"
                        fontWeight="600"
                        fontSize="20px"
                      >
                        Profit & Loss
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      md={3}
                      lg={3}
                      style={{ "align-self": "stretch" }}
                      paddingRight="15px"
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ height: "100%" }}
                      >
                        <DateRangePicker
                          showLeadingZeros={true}
                          onChange={onDateChange}
                          maxDate={new Date()}
                          value={selectedDate}
                          locale={CountryHelper.GetDateCulture(localSettings.country.Culture)}
                          clearIcon={null}
                          calendarIcon={
                            <CalendarMonthOutlinedIcon
                              style={{
                                "font-size": "24px",
                                "align-self": "center",
                                color: "#666666",
                              }}
                            />
                          }
                        />
                      </Stack>
                    </Grid>
                  </Grid>

                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="end"
                  >
                    {filterByBranch && (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        className="font-size-14"
                      >
                        <InputLabel id="Branch-label">Branch Name</InputLabel>
                        <Select
                          value={selectedBranch}
                          onChange={handleSelect}
                          label="Branch Name"
                          InputLabelProps={{ shrink: true }}
                          style={{
                            height: "50px",
                          }}
                        >
                          {branches.map((branch, index) => (
                            <MenuItem key={index} value={branch.branchId}>
                              {branch.branchName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    <Switch
                      checked={filterByBranch}
                      onChange={handleFilterByBranchChange}
                    />

                    <Typography
                      variant="h6"
                      style={{ "white-space": "nowrap", marginLeft: "0" }}
                      alignSelf="center"
                    >
                      Filter by branch
                    </Typography>
                  </Stack>
                </form>

                <iframe
                  className="reportIframe card"
                  srcdoc={reportContent}
                  style={{
                    width: "100%",
                    height: "calc(99vh - 200px)",
                    marginTop: "7px",
                  }}
                ></iframe>
                <br />
                <Grid container columnSpacing={1} padding={1}>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FormControl fullWidth>
                      <TextField
                        label="Email"
                        value={emailId}
                        type="email"
                        onChange={(e) => {
                          setEmailId(e.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sm={3}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FormControl fullWidth>
                      <Button
                        variant="contained"
                        className="btn btn-primary"
                        onClick={handleEmailClicked}
                      >
                        Send Email
                      </Button>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FormControl fullWidth>
                      <Button
                        className="btn btn-primary"
                        variant="contained"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Show Profit & Loss
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ProfitAndLossReport;
