import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import getSymbolFromCurrency from "currency-symbol-map";
import validator from "validator";
import Stack from "@mui/material/Stack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import TextField from "@mui/material/TextField";
import { getCountry } from "../../data/localSettingsActions";
import Utils from "../../core/Utils";
import { serviceItemActionTypes } from "../reducers/serviceItem/serviceItemActionTypes";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import DecimalEntryField from "./controls/DecimalEntryField";
import { getTaxAmount, getTotalPrice } from "../../helpers/productHelper";

const actions = {
  incrementAction:"incrementAction",
  decrementAction:"decrementAction",
  editAction:"editAction"
};

const PriceComponent=({price,currency,handleActions}) => {
  const handleEditPrice = (value) => {
    handleAction(actions.editAction, value);
  };
  const handleAction = (action, value) => {
    switch (action) {
      case actions.decrementAction:
        var oldPrice = Utils.ConvertToFloat(price);
        if (oldPrice < 1 || oldPrice === 0 || oldPrice === 0.0 || oldPrice === 0.00) {
          return;
        }
        handleActions(Utils.ConvertToFloat((oldPrice - 1.00).toFixed(2)));
        break;
      case actions.incrementAction:
        var oldPrice = Utils.ConvertToFloat(price);
        handleActions(Utils.ConvertToFloat((oldPrice + 1.00).toFixed(2)));
        break;
      case actions.editAction:
        handleActions(value);
        break;
    }
  };
  return(
    <Grid xs={8} lg={3} className="text-right" alignSelf="center">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ marginRight: "20px" }}
          >
            <RemoveCircleOutlineIcon
              fontSize="large"
              alignSelf="center"
              onClick={() => handleAction(actions.decrementAction)}
            />
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography
                level="h4"
                component="h4"
                fontWeight="600"
                fontSize="14px"
              >
                {getSymbolFromCurrency(currency)}
              </Typography>
              <DecimalEntryField
                fontWeight="600"
                setValue={handleEditPrice}
                className="inputField text-right"
                padding="0"
                style={{ "max-width": "150px" }}
                value={price}
              />
            </Stack>
            <AddCircleOutlineIcon
              fontSize="large"
              alignSelf="center"
              onClick={() => handleAction(actions.incrementAction)}
            />
          </Stack>
        </Grid>
  );
}

const QuantityComponent=({quantity,handleActions}) => { 
  const handleEditQuantity = (value) => {
    handleAction(actions.editAction, value);
  };
  const handleAction = (action, value) => {
    switch (action) {
      case actions.decrementAction:
        var oldQuantity = Utils.ConvertToFloat(quantity);
        if (oldQuantity < 1) {
          return;
        }
        handleActions(Utils.ConvertToFloat((oldQuantity - 1).toFixed(2)));
        break;
      case actions.incrementAction:
        var oldQuantity = Utils.ConvertToFloat(quantity);
        handleActions(Utils.ConvertToFloat((oldQuantity + 1).toFixed(2)));
        break;
      case actions.editAction:
        handleActions(value);
        break;
    }
  };
  return(
    <Grid xs={4} lg={2} alignSelf="center">
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <RemoveCircleOutlineIcon
              fontSize="large"
              alignSelf="center"
              onClick={() => handleAction(actions.decrementAction)}
            />
            <DecimalEntryField
              label=""
              type="text"
              alignSelf="center"
              variant="outlined"
              value={quantity}
              setValue={handleEditQuantity}
              className="inputField text-right"
              style={{ width: "80px" }}
              max={0}
            />
            <AddCircleOutlineIcon
              fontSize="large"
              alignSelf="center"
              onClick={() => handleAction(actions.incrementAction)}
            />
          </Stack>
        </Grid>
  );
}

const ServiceItem = ({ serviceItem, index, handleAction }) => {
  const [countryInfo, setCountryInfo] = useState({});
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    setCountryInfo(getCountry());
  }, []);

  useEffect(() => {
    if (Utils.IsNullOrEmptyObject(serviceItem)) {
      return;
    }
    setPrice(serviceItem.Price.toFixed(2));
    setQuantity(serviceItem.Quantity);
  }, [serviceItem]);

  const handleRemove = () => {
    var action = {
      type: serviceItemActionTypes.Delete,
      value: index,
    };
    handleAction(action);
  };

  const handlePrice = (newValue) => {
    setPrice(newValue);
    if (newValue === 0) {
      serviceItem.Price = newValue;
      serviceItem.TotalPrice = 0;
      serviceItem.TaxAmount = 0;
      serviceItem.TotalPriceIncludingTax = 0;
    } else {
      serviceItem.Price = newValue;
      serviceItem.InclusiveOfTax = false;
      serviceItem.TotalPrice = getTotalPrice(serviceItem.Price,serviceItem.Quantity);
      serviceItem.TaxAmount = getTaxAmount(false,serviceItem.TaxPercent,serviceItem.TotalPrice);
      serviceItem.TotalPriceIncludingTax =
        serviceItem.TotalPrice + serviceItem.TaxAmount;
    }
    var action = {
      type: serviceItemActionTypes.Edit,
      value: serviceItem,
    };
    handleAction(action);
  }

  const handleQuantity = (newValue) => {
    setQuantity(newValue);
    if (newValue === 0) {
      serviceItem.Quantity = "";
      serviceItem.TotalPrice = 0;
      serviceItem.TaxAmount = 0;
      serviceItem.TotalPriceIncludingTax = 0;
    } else {
      serviceItem.Quantity = newValue;
      serviceItem.TotalPrice = getTotalPrice(serviceItem.Price,serviceItem.Quantity);
      var totalPriceIncludingTax = getTotalPrice(serviceItem.PriceIncludingTax,serviceItem.Quantity);
      serviceItem.TaxAmount = getTaxAmount(serviceItem.InclusiveOfTax,serviceItem.TaxPercent,serviceItem.TotalPrice,totalPriceIncludingTax);
      serviceItem.TotalPriceIncludingTax = serviceItem.TotalPrice + serviceItem.TaxAmount;
    }
    var action = {
      type: serviceItemActionTypes.Edit,
      value: serviceItem,
    };
    handleAction(action);
  }

  return (
    <Grid flex="1" spacing={2} padding={1} className="card mb-h">
      <Grid container xs={12} padding="0 10px 0 0">
        <Grid xs={2} lg={1} alignSelf="center" className="remove-icon">
          <Tooltip title="Remove" placement="bottom">
            <Button onClick={handleRemove} className="remove-btn">
              <DeleteForeverRoundedIcon style={{ color: "#ff0000" }} />
            </Button>
          </Tooltip>
        </Grid>
        <Grid xs={10} lg={4} alignSelf="center">
          <Typography
            level="h2"
            className="h2"
            component="h4"
            fontWeight="600"
            fontSize="16px"
          >
            {serviceItem.Description}
          </Typography>
        </Grid>
        <PriceComponent price={price} currency={countryInfo.Currency} handleActions={handlePrice}/>
        <QuantityComponent quantity={quantity} handleActions={handleQuantity}/>
        <Grid xs={12} lg={1} className="text-right mt-xs-1" alignSelf="center">
          <Stack direction="row" justifyContent="end">
            <small className="visible-xs">Tax Percent : &nbsp;</small>
            <Typography
              level="h4"
              component="h4"
              fontWeight="600"
              fontSize="14px"
            >
              {Utils.IsNullOrEmpty(serviceItem.TaxPercent)
                ? 0 + "%"
                : serviceItem.TaxPercent.toFixed(2) + "%"}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12} lg={1} className="text-right" alignSelf="center">
          <Stack direction="row" justifyContent="end">
            <small className="visible-xs">Total Price : &nbsp;</small>
            <Typography
              level="h4"
              component="h4"
              fontWeight="600"
              fontSize="14px"
            >
              {Utils.GetCurrency(
                serviceItem.TotalPriceIncludingTax,
                countryInfo,
                2
              )}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ServiceItem;
