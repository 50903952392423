import React, { useState, useEffect } from "react";
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  Container,
  Grid,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppSettings from "../appsettings.json";
import Utils from "../core/Utils";
import { postAsync, postAsyncUnauthorized } from "../core/serviceClient";
import { useDispatch } from "react-redux";
import { connectToStore } from "../data/store";
import {
  setLogin,
  setToken,
  getEnterpriseId,
  setCountry
} from "../data/localSettingsActions";
import MessagePopup from "./common/MessagePopUp";
import ConfirmPopup from "./common/ConfirmPopup";
import { India, HideRegisteration, Enterprises, AlertSMSNotSupported } from "../core/Constants";
import CountryHelper from "../core/CountryHelper";
import Loader from "./common/Loader";
import NumericTextField from "./common/controls/NumericTextField";
import { CanUseWebApp } from "../core/ModuleHelper";
import Login_Bg from "../images/login-bg.png";
import Infopoint_Logo from "../images/Infopoint.png";
import { LandingPage, OverviewPage, RegisterPage } from "../core/PageConstants";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const Login = () => {
  document.body.classList.add("login");
  document.title = "Login";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [loginId, setLoginId] = useState("");
  const [loggedInWith, setLoggedInWith] = useState("Email");
  const [otp, setOTP] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [isMobileLogin, setIsMobileLogin] = useState(false);
  const [loginType, setLoginType] = useState(false);
  const [loginResponse, setLoginResponse] = useState("");
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [loginDisabled,setLoginDisabled] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [countries, setCountries] = useState([]);
  const [loginCountry, setLoginCountry] = useState({});
  const [selectedCountry,setSelectedCountry] = useState({});
  const localSettings = connectToStore();

  const handleSubmit = (e) => {
    e.preventDefault();
    processLogin();
  };

  const fetchLoginInfo = () => {
    var isDisconnected = localStorage.getItem("IsDisconnected");
    if (localSettings.authenticated && isDisconnected !== "true") {
      navigate(LandingPage.Path);
    }
  };

  useEffect(() => {
    var countries = CountryHelper.GetSupportedCountries();
    if (!Utils.IsNullOrEmptyArray(countries)) {
      setCountries(countries);
      var index = Utils.GetIndex(India.Code, countries, "Code");
      var tempLoginCountry = countries[index > -1 ? index : 0];
      setLoginCountry(tempLoginCountry);
      var tempSelectedCountry = CountryHelper.GetCountryObject(tempLoginCountry.Code);
      setSelectedCountry(tempSelectedCountry);
      setCountry(tempSelectedCountry,dispatch);
    }
    fetchLoginInfo();
  }, []);

  const handleKeyUp = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      processLogin();
    }
  };
  function loginMobile() {
    setShowOTP(false);
    setLoginId("");
    setLoggedInWith("Mobile");
    setIsMobileLogin(true);
    setLoginType(true);
  }

  function loginEmail() {
    setShowOTP(false);
    setLoginId("");
    setLoggedInWith("Email");
    setLoginType(false);
    setIsMobileLogin(false);
  }

  async function processLogin() {
    setIsLoading(true);
    if (Utils.IsNullOrEmpty(loggedInWith))
      setLoggedInWith(!isMobileLogin ? "Email" : "Mobile");
    if (!showOTP) {
      if (!isMobileLogin && !Utils.IsEmailValid(loginId)) {
        setMsgOpen(true);
        setMsgText("Please enter a valid email address");
      } else if (isMobileLogin && !Utils.IsMobileValid(loginId,selectedCountry)) {
        setMsgOpen(true);
        setMsgText("Please enter a valid mobile number");
      } else await summitLoginDetails();
    } else {
      if (Utils.IsNullOrEmpty(otp) || otp.length != 6) {
        setMsgOpen(true);
        setMsgText("Please enter a valid OTP");
      } else await processOTP();
    }
    setIsLoading(false);
  }
  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    if (value == "OK") {
      navigate(RegisterPage.Path);
    }
  };
  async function summitLoginDetails() {
    let enterpriseId = getEnterpriseId();
    var request = {
      LoginId: loginId,
      LoggedInWith: loggedInWith,
      Secret: AppSettings.Secret,
      CountryCode: loginCountry.Code,
      OTP: true,
      OTPSender:
        enterpriseId === Enterprises.XUVI ? "Infopoint" : "Ceramic Pro",
      EnterpriseId: enterpriseId,
    };
    setOTP("");
    let result = await postAsyncUnauthorized("Auth/Login", request);
    if (result.error) {
      setMsgOpen(true);
      setMsgText(result.errorMessage);
      return;
    } else if (
      Utils.IsNullOrEmptyObject(result.data) ||
      Utils.IsNullOrEmptyObject(result.data.user)
    ) {
      if (HideRegisteration) {
        setMsgText(
          "Account not found - Please use Android or iOS device to create an account."
        );
        setMsgOpen(true);
      } else {
        setConfirmText(
          "Account not found - would you like to register for a new account?"
        );
        setConfirmOpen(true);
      }
      return;
    } else if (result.data.admin) {
      setMsgText(
        "Admin feature is not available in web, please use the mobile or desktop."
      );
      setMsgOpen(true);
      return true;
    } else {
      const business = result.data.businessEntityConfig;
      //var webModule = business.enabledModules.find((m) => m.key == "WebApp");
      if (!CanUseWebApp(business)) {
        setMsgText(
          "Web app access is not available for this business. Please contact our support team."
        );
        setMsgOpen(true);
        return;
      }
      var subscriptionEndDate = new Date(business.subscriptionEndDate);
      var trialEndDate = new Date(business.trialEndDate);
      if (
        subscriptionEndDate.getFullYear() != 1 &&
        subscriptionEndDate <= new Date()
      ) {
        setMsgText(
          `Your subscription expired on ${new Date(
            business.subscriptionEndDate
          ).toLocaleDateString()} . Please contact our support team to renew your subscription.`
        );
        setMsgOpen(true);
        return;
      }
      if (
        subscriptionEndDate.getFullYear() == 1 &&
        trialEndDate <= new Date()
      ) {
        setMsgText(
          `Your trial expired on ${new Date(
            business.trialEndDate
          ).toLocaleDateString()} . Please contact our support team to buy a subscription.`
        );
        setMsgOpen(true);
        return;
      }
      if (business.locked) {
        setMsgText(
          "This business is locked from access. Please contact our support team to buy a subscription."
        );
        setMsgOpen(true);
        return;
      }
      if (!result.data.user.active) {
        setMsgText(
          "This user is not active in this business. Please contact your business owner to enable access."
        );
        setMsgOpen(true);
        return;
      }
      var userInfoObj = {
        u_info: {
          userId: result.data.user.id,
          emailId: result.data.user.emailId,
          firstName: result.data.user.firstName,
          language: result.data.user.language,
          lastAccessed: result.data.user.lastAccessed,
        },
      };
      localStorage.setItem("user-info", JSON.stringify(userInfoObj));
      localStorage.setItem(
        "business-entity",
        JSON.stringify(result.data.businessEntityConfig)
      );
      setLoginResponse(result);
      setShowOTP(true);
    }
  }

  async function processOTP() {
    setToken(loginResponse.data.token, dispatch);
    var request = {
      UserId: loginId,
      OTP: otp,
    };
    let result = await postAsync("Account/OTPAuthentication", request, "");
    if (result.data) {
      setLogin(loginResponse.data, dispatch);
      navigate(OverviewPage.Path);
    } else {
      setMsgOpen(true);
      setMsgText("Invalid OTP");
      setShowOTP(false);
    }
  }
  const handleMsgClose = () => {
    setMsgOpen(false);
  };

  const onSelectCountry = (event) => {
    var tempCountry = event.target.value;
    var countryObj = CountryHelper.GetCountryObject(tempCountry.Code);
    if (!countryObj.SMSEnabled) {
      setMsgOpen(true);
      setMsgText(AlertSMSNotSupported);
      return;
    }
    setSelectedCountry(countryObj);
    setCountry(countryObj, dispatch);
    setLoginCountry(tempCountry);
    setLoginId("");
  };

  return (
    <Container
      maxWidth="full"
      className="main"
      sx={{ display: "flex", padding: "0px !important" }}
    >
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />
      <ConfirmPopup
        confirmOpen={confirmOpen}
        confirmText={confirmText}
        onConfirmClose={handleConfirmClose}
      />
      <Grid container xs={12}>
        <Grid xs={12} sm={8} md={8} alignSelf="center" className="text-center">
          <Box component="img" src={Login_Bg} style={{ "max-width": "100%" }} />
        </Grid>
        <Grid xs={12} sm={4} md={4}>
          <div className="lg-sec form-sec">
            <div className="lg-form">
              <div className="p-a-0">
                <Stack className="logo-sec" direction="row">
                  <Box component="img" src={Infopoint_Logo} />
                  <h1>Infopoint</h1>
                </Stack>
                <h1>Login</h1>
                <h2 className="m-t-1">
                  Stay connected with your business always!
                </h2>
                <p>
                  Best business management software - Automobiles, Salon &amp;
                  Spa, Health &amp; Wellness
                </p>
              </div>
              <div className="p-a-0 m-t-2 cs-form text-left">
                {isMobileLogin ? (
                  <Stack direction="row" spacing={2}>
                    <FormControl sx={{ m: 1, minWidth: 80 }}>
                      <InputLabel id="country-label">Country</InputLabel>
                      <Select
                        labelId="country-label"
                        id="country"
                        value={loginCountry}
                        variant="outlined"
                        label="Country *"
                        onChange={(e) => onSelectCountry(e)}
                      >
                        {!Utils.IsNullOrEmptyArray(countries) &&
                          countries.map((country) => (
                            <MenuItem s value={country}>
                              {country.Name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <NumericTextField
                      autoComplete="off"
                      id="mobile"
                      className="input"
                      label="Mobile *"
                      type="tel"
                      variant="outlined"
                      inputProps={{ maxLength: Utils.IsNullOrEmptyObject(selectedCountry) ? 10 : selectedCountry.MobileNumberMaxLength }}
                      fullWidth={true}
                      name="mobile"
                      placeholder="Mobile"
                      value={loginId}
                      autoFocus={true}
                      setValue={setLoginId}
                      onKeyUp={(e) => handleKeyUp(e)}
                    />
                  </Stack>
                ) : (
                  <div>
                    <TextField
                      autoComplete="on"
                      className="input"
                      id="username"
                      type="email"
                      variant="outlined"
                      label="Email *"
                      placeholder="Email"
                      fullWidth={true}
                      name="username"
                      value={loginId}
                      autoFocus={true}
                      onChange={(e) => setLoginId(e.target.value)}
                      onKeyUp={(e) => handleKeyUp(e)}
                    />
                  </div>
                )}

                {showOTP ? (
                  <div className="otp-sec m-t-1">
                    <NumericTextField
                      type="text"
                      name="otp"
                      className="mand-fld"
                      id="otp"
                      fullWidth={true}
                      inputProps={{ maxLength: 6 }}
                      variant="outlined"
                      label="OTP *"
                      placeholder="OTP"
                      autoComplete="off"
                      value={otp}
                      setValue={setOTP}
                      onKeyUp={(e) => handleKeyUp(e)}
                    />
                  </div>
                ) : null}

                <div className="m-t-1 text-center">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={loginDisabled}
                    className="submitBtn cs-btn"
                    fullWidth={true}
                    onClick={handleSubmit}
                  >
                    Log In
                  </Button>
                </div>

                <div className="p-a-0 text-center m-t-1">
                  <p className="m-t-2">
                    <small className="text-muted">or</small> Log In with
                  </p>
                  <p className="m-t-2">
                    {loginType ? (
                      <Button variant="outlined" onClick={loginEmail}>
                        <MailOutlineIcon /> &nbsp;
                        <small>Email</small>
                      </Button>
                    ) : (
                      <Button variant="outlined" onClick={loginMobile}>
                        <SmartphoneIcon /> &nbsp;
                        <small>Mobile</small>
                      </Button>
                    )}
                    &nbsp;
                  </p>
                  {!HideRegisteration && (
                    <p className="m-t-2">
                      New here? <a href="./register">Register</a>.
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xs-12 copyrights">
              <p>
                <a href="https://xuvi.com/privacy-policy.html" target="_blank">
                  Privacy Policy
                </a>
                | Copyright &copy; {new Date().getFullYear()}. Powered by
                <a
                  href="https://xuvi.com/"
                  target="_blank"
                  style={{ "margin-left": "3px" }}
                >
                  XUVI
                </a>
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
