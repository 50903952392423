import { Regexes } from "./Regexes";

export const HideOtherMenues = true;
export const HideRegisteration = true;
export const HideSettingsMenus = true;
export const DefaultDate = "0001-01-01T00:00:00Z";
export const DefaultDateFromServer = "0001-01-01T00:00:00";
export const MaxDateLimit = 90;
export const MaxFileSizeLimit = 10485760;
export const DefaultCulture = "en";
export const NotApplicable = "Not Applicable";
export const AlertSMSNotSupported =
  "SMS support is not currently available in your region. We apologize for any inconvenience.";
export const India = {
  Code: "+91",
  ISOCode: "in",
  Name: "India",
  ShortName: "IN",
  Culture: "en-IN",
  Currency: "INR",
  CurrencySmall: "inr",
  TermsAndConditionURL: "https://infopoint.com/business-tandc-in.html",
  ContactUsURL: "https://infopoint.com/contactus-in.html",
  TaxIdLength: 15,
  MobileNumberMaxLength: 10,
  MobileNumberMinLength: 10,
  SMSEnabled: true,
  MobileNumberRegex: Regexes.OnlyNumeric,
};

export const USA = {
  Code: "+1",
  ISOCode: "us",
  Name: "USA",
  ShortName: "US",
  Culture: "en-US",
  Currency: "USD",
  CurrencySmall: "usd",
  TermsAndConditionURL: "https://infopoint.com/business-tandc-us.html",
  ContactUsURL: "https://infopoint.com/contactus-us.html",
  TaxIdLength: 9,
  MobileNumberMaxLength: 10,
  MobileNumberMinLength: 10,
  SMSEnabled: true,
  MobileNumberRegex: Regexes.OnlyNumeric,
};

export const UAE = {
  Code: "+971",
  ISOCode: "ae",
  Name: "UAE",
  ShortName: "AE",
  Culture: "ar-AE",
  Currency: "AED",
  CurrencySmall: "aed",
  TermsAndConditionURL: "https://infopoint.com/business-tandc-us.html",
  ContactUsURL: "https://infopoint.com/contactus-us.html",
  TaxIdLength: 15,
  MobileNumberMaxLength: 10,
  MobileNumberMinLength: 9,
  SMSEnabled: true,
  MobileNumberRegex: Regexes.UAEMobile,
};

export const Nepal = {
  Code: "+977",
  ISOCode: "np",
  Name: "Nepal",
  ShortName: "NP",
  Culture: "ne-NP",
  Currency: "NPR",
  CurrencySmall: "npr",
  TermsAndConditionURL: "https://infopoint.com/business-tandc-us.html",
  ContactUsURL: "https://infopoint.com/contactus-us.html",
  TaxIdLength: 15,
  MobileNumberMaxLength: 10,
  MobileNumberMinLength: 10,
  SMSEnabled: false,
  MobileNumberRegex: Regexes.OnlyNumeric,
};

export const Malaysia = {
  Code: "+60",
  ISOCode: "my",
  Name: "Malaysia",
  ShortName: "MY",
  Culture: "ms-MY",
  Currency: "MYR",
  CurrencySmall: "myr",
  TermsAndConditionURL: "https://infopoint.com/business-tandc-us.html",
  ContactUsURL: "https://infopoint.com/contactus-us.html",
  TaxIdLength: 15,
  MobileNumberMaxLength: 11,
  MobileNumberMinLength: 6,
  SMSEnabled: false,
  MobileNumberRegex: Regexes.MalaysiaMobile,
};

export const UserRoles = {
  GlobalAdmin: "GlobalAdmin",
  BranchAdmin: "BranchAdmin",
  Operator: "Operator",
  Technician: "Technician",
};

export const BusinessCategories = {
  Food: "Food",
  Grocery: "Grocery",
  Salon: "Salon",
  Automobile: "Automobile",
  Other: "Other",
};

export const ConfirmTypes = {
  YES: "YES",
  NO: "NO",
  CANCEL: "CANCEL",
  CLOSE: "CLOSE",
  OK: "OK",
};

export const OfferClassificationTypes = {
  Campaign: "Campaign",
  AMC: "AMC",
  OfferSource: "OfferSource",
};

export const BenefitTypes = {
  DiscountPercentage: "DiscountPercentage",
  Free: "Free",
  FlatDiscount: "FlatDiscount",
};

export const MeasuringUnitsArray = [
  "Number",
  "Gram",
  "Kilogram",
  "Millilitre",
  "Litre",
  "Meter",
];

export const MeasuringUnits = {
  Number: "Number",
  Gram: "Gram",
  Kilogram: "Kilogram",
  Millilitre: "Millilitre",
  Litre: "Litre",
  Meter: "Meter",
};

export const VehicleTypes = {
  None: "None",
  TwoWheeler: "TwoWheeler",
  FourWheeler: "FourWheeler",
};

export const ClassificationTypes = {
  Class: "Class",
  Department: "Department",
  Item: "Item",
  All: "All",
};

export const ServiceItemTypes = {
  None: "None",
  Spare: "Spare",
  Service: "Service",
  Consumable: "Consumable",
  Labour: "Labour",
  Plan: "Plan",
};

export const OfferTypes = {
  Cash: "Cash",
  Free: "Free",
  Multiplier: "Multiplier",
  PercentDiscount: "PercentDiscount",
  Points: "Points",
};

export const CampaignTypes = {
  Advertisement: "Advertisement",
  BuyXGetY: "BuyXGetY",
  Event: "Event",
  SpendXGetY: "SpendXGetY",
  VisitXGetY: "VisitXGetY",
};

export const PassEventTypes = {
  Birthday: "Birthday",
  Holiday: "Holiday",
  Signup: "Signup",
  Visit: "Visit",
};

export const CampaignSegmentTypes = {
  Tier: "Tier",
  Location: "Location",
};

export const CampaignProductCategories = {
  Class: "Class",
  Department: "Department",
  Fineline: "Fineline",
  Item: "Item",
};

export const PaymentTypes = {
  Cash: "Cash",
  AutoPay: "AutoPay",
  PayTerminal: "PayTerminal",
  PayLater: "PayLater",
  PayNow: "PayNow",
  CardPay: "CardPay",
  UPI: "UPI",
  NetBanking: "NetBanking",
  PaymentLink: "PaymentLink",
};

export const VisitStatuses = {
  Completed: "Completed",
  PaymentRefunded: "PaymentRefunded",
  ReservationCanceled: "ReservationCanceled",
  Void: "Void",
  Open: "Open",
  WorkInProgress: "WorkInProgress",
  Delivered: "Delivered",
  Assigned: "Assigned",
  PartiallyPaid: "PartiallyPaid",
};

export const SideBarMenuNames = {
  Overview: "Overview",
  AMCPlan: "AMCPlan",
  JobCard: "JobCard",
  Visits: "Visits",
  CheckOut: "CheckOut",
  Customers: "Customers",
  Settings: "Settings",
  Campaigns: "Campaigns",
  Logout: "Logout",
  Estimate: "Estimate",
};

export const SettingsMenuNames = {
  AdminFunctions: "Admin Functions",
  BusinessSettings: "Business Settings",
  BusinessReports: "Business Reports",
  POSSettings: "POS Settings",
  AppSettings: "App Settings",
};

export const AdminFunctionNames = {
  AdminUsers: "Admin Users",
  SystemConfig: "System Config",
  SystemLogs: "System Logs",
  BusinessConfig: "Business Config",
};
export const BusinessSettingNames = {
  BusinessSetup: "Business Setup",
  BranchSetup: "Branch Setup",
  CustomerSetup: "Customer Setup",
  CustomerGroupSetup: "Customer Group Setup",
  PayoutSetup: "Payout Setup",
  SparesSetup: "Spares Setup",
  ProductSetup: "Product Setup",
  ServicesOrLabourSetup: "Services / Labour Setup",
  ServiceSetup: "Service Setup",
  ConsumablesSetup: "Consumables Setup",
  MaterialSetup: "Material Setup",
  Enquiries: "Enquiries",
  UPISetup: "UPI Setup",
  TierSetup: "Tier Setup",
  UserSetup: "User Setup",
  AMCPlanSetup: "AMC Plan Setup",
};
export const BusinessReportNames = {
  Invoice: "Invoice",
  Statement: "Statement",
  Expenses: "Expenses",
  ProfitAndLoss: "Profit & Loss",
  JobCards: "Job Cards",
  Staff: "Staff",
  Employee: "Employee",
};
export const POSSettingNames = {
  IsCashDrawerInstalled: "Cash Drawer Installed?",
  FixedPOSTerminal: "Fixed POS Terminal",
  HandheldBarcodeScanner: "Handheld Barcode Scanner",
  LoadProductsBeforeSearch: "Load Products Before Search",
  TerminalId: "Terminal Id",
  IsThisDeviceIsSidecar: "This device is Sidecar?",
  SidecarIdOrOtherDevice: "Sidecar Id (Other Devices Terminal Id)",
  TerminalSoftwareUpdate: "Terminal Software Update",
};
export const AppSettingNames = {
  BiometricAuthentication: "Biometric Authentication",
  Notifications: "Notifications",
  Language: "Language",
  UsagePlans: "Usage Plans",
  ContactUs: "Contact Us",
  Version: "Version",
};
export const SettingsMenuList = {
  AdminFunctions: AdminFunctionNames,
  BusinessSettings: BusinessSettingNames,
  BusinessReports: BusinessReportNames,
  POSSettings: POSSettingNames,
  AppSettings: AppSettingNames,
};
export const InvoiceFilters = {
  All: "All",
  GSTInvoices: "GST Invoices",
  NonGSTInvoices: "Non GST Invoices",
};
export const RevenueFilters = {
  Product: "Product",
  Category: "Category",
  Department: "Department",
};

export const VisitTypes = {
  All: "All",
  CheckOut: "Checkout",
  JobCard: "Jobcard",
  Delayed: "Delayed",
  MyJobCard: "MyJobCard",
};

export const PaymentMethodTypes = {
  BankAccount: "BankAccount",
  NetBanking: "NetBanking",
  Card: "Card",
  Cash: "Cash",
  Klarna: "Klarna",
  Online: "Online",
  Points: "Points",
  Wallet: "Wallet",
  UPI: "Upi",
  UnPaid: "UnPaid",
  PaymentLink: "PaymentLink",
};

export const VisitTypeEnum = {
  Checkout: "Checkout",
  Jobcard: "Jobcard",
  Estimate: "Estimate",
};

export const ServiceLocationTypes = {
  ServiceStartLocation: "ServiceStartLocation",
  ServiceEndLocation: "ServiceEndLocation",
};

export const ExpenseStatus = {
  Active: "Active",
  Void: "Void",
};

export const Enterprises = {
  XUVI: "XUVI",
  CeramicPro: "CPRO",
  DetailingDaddy: "DDDY",
  DetailingBull: "DBLL",
};

export const ProductDetailsTypes = {
  Consumables: "Consumables",
  Services: "Services",
  Spares: "Spares",
};

export const CustomerBookingActions = {
  WrongOTP: "WrongOTP",
  ProceedReservation: "ProceedReservation",
};

export const AMCPlanStatus = {
  Pending: "Pending",
  Approved: "Approved",
};

export const EstimateStatus = {
  Open: "Open",
  Closed: "Closed",
  Void: "Void",
};

export const RazorpayPaymentLinkStatuses = {
  Captured: "captured",
  Created: "created",
  Cancelled: "cancelled",
  PartiallyPaid: "partially_paid",
  Paid: "paid",
  Expired: "expired",
};

export const PartialPaymentErrorTypes = {
  None: "None",
  Completed: "Completed",
  AmountExceeds: "AmountExceeds",
};
