import { connectToStore } from "../data/store";
import { getBusiness } from "../data/localSettingsActions";
import Utils from "./Utils";
import AppSettings from "../appsettings.json";
import moment from "moment";
import CountryHelper from "../core/CountryHelper";

const devUrl = Utils.GetServiceUrl();
//const stagingUrl = 'https://www.xuvi.com/Infopoint'
//const productionUrl = 'https://infopoint.com/Services'
const geocodeUrl =
  "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCk2uVaMZuNW--_uwSDwq8GxYWNDD7KN2Q&address=";

export const getCurrentLocalDTISOString = () => {
  return moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
};

export const throwServerOfflineError = (set = true) => {
  if (set) {
    localStorage.setItem("IsDisconnected", true);
  }
  return {
    error: true,
    errorMessage: "Server is not active",
    isNavigateToLogin: true,
  };
};

export const generateErrorResult = (response) => {
  let result = {
    error: true,
    errorMessage: "Something went wrong",
    isNavigateToLogin: true,
  };
  if (!Utils.IsNullOrEmpty(response)) {
    switch (response.status) {
      case 204:
      case 400:
      case 401:
      case 403:
      case 404:
      case 500:
        result.errorMessage = response.statusText;
        break;
    }
  }
  return result;
};

export const postAsync = async (
  url,
  request = {},
  token = "",
  operation = "",
  queryId = "",
  queryParameters = []
) => {
  let state = connectToStore();
  if (Utils.IsNullOrEmpty(token)) {
    token = state.token;
  }
  if (Utils.IsNullOrEmpty(operation)) {
    operation = "Execute";
  }
  let culture = "";
  if (!Utils.IsNullOrEmptyObject(state.country))
    culture = state.country.Culture;
  if (
    Utils.IsNullOrEmpty(culture) &&
    !Utils.IsNullOrEmptyObject(state.branch.country)
  )
    culture = state.branch.country.Culture;
  var apiCulture = CountryHelper.GetAPICulture(culture);
  let result = await fetch(devUrl + url + "?culture=" + apiCulture, {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify({
      BusinessId:
        Utils.IsNullOrEmpty(state.business) == true ? "" : state.business.id,
      BranchId:
        Utils.IsNullOrEmpty(state.branch) == true ? "" : state.branch.branchId,
      UserId: Utils.IsNullOrEmpty(state.user) == true ? "" : state.user.id,
      Username:
        Utils.IsNullOrEmpty(state.user) == true ? "" : state.user.userName,
      Data: request,
      Operation: operation,
      CurrentLocalDT: getCurrentLocalDTISOString(),
      QueryId: queryId,
      QueryParameters: queryParameters,
      IsWebApp: true,
    }),
  })
    .then((res) => {
      localStorage.setItem("IsDisconnected", false);
      if (res.status === 200) {
        return res.json();
      } else {
        return generateErrorResult(res);
      }
    })
    .catch((error) => {
      return throwServerOfflineError();
    });
  return result;
};

export const postAsyncUnauthorized = async (url, request) => {
  let state = connectToStore();
  let result = await fetch(devUrl + url, {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      BusinessId:
        Utils.IsNullOrEmpty(state.business) == true ? "" : state.business.id,
      BranchId:
        Utils.IsNullOrEmpty(state.branch) == true ? "" : state.branch.branchId,
      UserId: Utils.IsNullOrEmpty(state.user) == true ? "" : state.user.id,
      Username:
        Utils.IsNullOrEmpty(state.user) == true ? "" : state.user.userName,
      Data: request,
      Operation: "Execute",
      CurrentLocalDT: getCurrentLocalDTISOString(),
      IsWebApp: true,
    }),
  })
    .then((res) => {
      localStorage.setItem("IsDisconnected", false);
      if (res.status === 200) {
        return res.json();
      } else {
        return generateErrorResult(res);
      }
    })
    .catch((error) => {
      return throwServerOfflineError();
    });
  return result;
};

export const getToken = async () => {
  return await postAsyncUnauthorized("Token/GetToken", {
    Id: AppSettings.Secret,
    PublicKey: AppSettings.PublicKey,
    PublicKeyType: "AES",
  });
};

export const loadProductsFromServer = async () => {
  var business = getBusiness();
  return await postAsync(
    "Product/GetProduct",
    {},
    "",
    "GetAll",
    "AllProductsAndMaterialsForBusiness",
    [business.id]
  );
};

export const getGeocodeAddress = async (address) => {
  var addressEncoded = encodeURIComponent(address);
  var url = geocodeUrl + addressEncoded;
  var response = await fetch(url)
    .then((res) => {
      if (res.status == 200) {
        return res.json();
      } else {
        return generateErrorResult(res);
      }
    })
    .catch((error) => {
      return throwServerOfflineError();
    });
  return response;
};

export const getAsync = async (url, params) => {
  let state = connectToStore();
  let result = await fetch(devUrl + url + "?" + params, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: "Bearer " + state.token,
    },
  })
    .then((res) => {
      localStorage.setItem("IsDisconnected", false);
      if (res.status === 200) {
        return res.json();
      } else {
        return generateErrorResult(res);
      }
    })
    .catch((error) => {
      return throwServerOfflineError();
    });
  return result;
};
export const getPartialViewAsync = async (url, params) => {
  let state = connectToStore();
  let result = await fetch(devUrl + url + "?" + params, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: "Bearer " + state.token,
    },
  })
    .then((res) => {
      localStorage.setItem("IsDisconnected", false);
      if (res.status === 200) {
        return res.text();
      } else {
        return generateErrorResult(res);
      }
    })
    .catch((error) => {
      return throwServerOfflineError();
    });
  return result;
};
