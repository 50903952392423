import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "../Style.css";
import "../bootstrap.min.css";
import { Stack, Card, Typography, Grid, Box, Switch } from "@mui/material";
import Logo_Icon from "../images/Infopoint.png";
import { useNavigate } from "react-router-dom";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import NoCrashOutlinedIcon from "@mui/icons-material/NoCrashOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Handshake from "@mui/icons-material/Handshake";
import { Link } from "react-router-dom";
import Utils from "../core/Utils";
import UserHelper from "../core/UserHelper";
import ConfirmPopup from "./common/ConfirmPopup";
import { CardActionArea } from "@mui/material";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import {
  HideOtherMenues,
  BusinessCategories,
  ConfirmTypes,
  SideBarMenuNames,
  UserRoles,
  UAE,
  USA,
} from "../core/Constants";
import {
  getBusiness,
  getBranch,
  getCountry,
  getUser,
  getIsDarkMode,
  setIsDarkMode,
} from "../data/localSettingsActions";
import BranchPopup from "./common/BranchPopup";
import BusinessHelper from "../core/BusinessHelper";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import {
  LoginPage,
  CheckOutPage,
  CustomersPage,
  OverviewPage,
  SettingsPage,
  VisitsPage,
  AMCPlanPage,
  EstimatesPage,
} from "../core/PageConstants";
import SessionTimeOut from "./common/SessionTimeOut";

const SideBarMenuItems = ({ selectedTab }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [showBranch, setShowBranch] = useState(false);
  const [branch, setBranch] = useState({});
  const [user, setUser] = useState({});
  const [countryInfo, setCountryInfo] = useState({});
  const [businessCategory, setBusinessCategory] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDark, setIsDark] = useState(getIsDarkMode());
  const [isSessionTimeOut, setIsSessionTimeOut] = useState(false);

  const Logout = () => {
    setConfirmText("Continue logging out?");
    setConfirmOpen(true);
  };

  useEffect(() => {
    var head = document.getElementsByTagName("head")[0];
    if (isDark) {
      document.getElementById("DarkMode").href = "./DarkMode.css";
    } else {
      document.getElementById("DarkMode").href = "./LightMode.css";
    }
  }, [isDark]);

  useEffect(() => {
    loadValues();
  }, [selectedTab]);

  const loadValues = () => {
    if (Utils.IsNullOrEmpty(selectedTab)) {
      return;
    }
    setBranch(getBranch());
    setUser(getUser());
    setCountryInfo(getCountry());
    setBusinessCategory(BusinessHelper.GetBusinessCategory(getBusiness()));
  };

  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    if (value == ConfirmTypes.OK) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const handleBranchChange = (branchSelected, branch) => {
    setShowBranch(false);
    if (branchSelected) {
      navigate(0);
    }
  };

  const handleBranchOpen = () => {
    setShowBranch(user.role === UserRoles.GlobalAdmin);
  };

  const handleDarkModeChange = (event) => {
    setIsDark(event.target.checked);
    setIsDarkMode(event.target.checked, dispatch);
    if (event.target.checked) {
      document.getElementById("DarkMode").href = "./DarkMode.css";
    } else {
      document.getElementById("DarkMode").href = "./LightMode.css";
    }
  };

  return (
    <Grid className="leftMenu">
      <SessionTimeOut open={isSessionTimeOut} />
      <BranchPopup
        dispatch={dispatch}
        open={showBranch}
        onClose={handleBranchChange}
      />
      <input type="checkbox" className="menu-check" id="menuCheck" />
      <label
        for="menuCheck"
        size="large"
        className="btn btn-secondary refresh-icon menu-icon"
      >
        <MenuOutlinedIcon className="menuOpen" />
        <CloseOutlinedIcon className="menuClose" />
      </label>
      <Grid className="leftMenuInner">
        <Stack direction="row" spacing={1} mt={3} className="white-text">
          <Box
            alignSelf="center"
            className="logo-img"
            component="img"
            sx={{
              width: 54,
            }}
            src={Logo_Icon}
            onClick={handleBranchOpen}
          />
          <Typography
            level="h1"
            component="h1"
            fontWeight="600"
            fontSize="1.7rem"
            className="h1 logo-text"
            alignSelf="center"
          >
            {branch.branchName}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          spacing={0}
          justifyContent="flex-end"
          className="topHeader"
        >
          <ConfirmPopup
            confirmOpen={confirmOpen}
            confirmText={confirmText}
            onConfirmClose={handleConfirmClose}
          />
          <Card
            className={
              "card " +
              (selectedTab == SideBarMenuNames.Overview ? "active" : null)
            }
          >
            <Link to={OverviewPage.Path}>
              <CardActionArea>
                <PieChartOutlineIcon fontSize="large" />
                <Typography component="span">Overview</Typography>
              </CardActionArea>
            </Link>
          </Card>

          <Card
            className={
              "card " +
              (selectedTab == SideBarMenuNames.Visits ? "active" : null)
            }
          >
            <Link to={VisitsPage.Path} state={{}}>
              <CardActionArea>
                <FactCheckOutlinedIcon fontSize="large" />
                <Typography component="span">Visits</Typography>
              </CardActionArea>
            </Link>
          </Card>
          {countryInfo.Code !== USA.Code && (businessCategory === BusinessCategories.Automobile || businessCategory === BusinessCategories.Salon) && (
            <Card
              className={
                "card " +
                (selectedTab == SideBarMenuNames.AMCPlan ? "active" : null)
              }
            >
              <Link to={AMCPlanPage.Path}>
                <CardActionArea>
                  <Handshake fontSize="large" />
                  <Typography component="span">
                    Membership Plan
                  </Typography>
                </CardActionArea>
              </Link>
            </Card>
          )}

          {businessCategory == BusinessCategories.Automobile && (
            <>
              <Card
                className={
                  "card " +
                  (selectedTab == SideBarMenuNames.JobCard ? "active" : null)
                }
              >
                <Link to={CustomersPage.Path} state={{ fromJobcard: true }}>
                  <CardActionArea>
                    <NoCrashOutlinedIcon fontSize="large" />
                    <Typography component="span">Job Card</Typography>
                  </CardActionArea>
                </Link>
              </Card>
              {countryInfo.Code === UAE.Code && businessCategory == BusinessCategories.Automobile && (
                <Card
                  className={
                    "card " +
                    (selectedTab == SideBarMenuNames.Estimate ? "active" : null)
                  }
                >
                  <Link to={EstimatesPage.Path} state={{}}>
                    <CardActionArea>
                      <RequestQuoteIcon fontSize="large" />
                      <Typography component="span">Estimate</Typography>
                    </CardActionArea>
                  </Link>
                </Card>
              )}
            </>
          )}
          <Card
            className={
              "card " +
              (selectedTab == SideBarMenuNames.CheckOut ? "active" : null)
            }
          >
            <Link
              to={CheckOutPage.Path}
              state={{
                onSuccess: OverviewPage.Path,
                navigationStack: [OverviewPage.Path],
              }}
            >
              <CardActionArea>
                <CheckCircleOutlineOutlinedIcon fontSize="large" />
                <Typography component="span">
                  {businessCategory == BusinessCategories.Salon
                    ? "Billing"
                    : "Check-Out"}
                </Typography>
              </CardActionArea>
            </Link>
          </Card>
          <Card
            className={
              "card " +
              (selectedTab == SideBarMenuNames.Customers ? "active" : null)
            }
          >
            <Link to={CustomersPage.Path} state={{ fromJobcard: false }}>
              <CardActionArea>
                <GroupsOutlinedIcon fontSize="large" />
                <Typography component="span">Customers</Typography>
              </CardActionArea>
            </Link>
          </Card>
          <Card
            className={
              "card " +
              (selectedTab == SideBarMenuNames.Settings ? "active" : null)
            }
          >
            <Link to={SettingsPage.Path}>
              <CardActionArea>
                <TuneOutlinedIcon fontSize="large" />
                <Typography component="span">Settings</Typography>
              </CardActionArea>
            </Link>
          </Card>
        </Stack>
        <Stack className="last-login topHeader">
          {/* <Card
            className={
              "card " +
              (selectedTab == SideBarMenuNames.Logout ? "active" : null)
            }
          >
            <Link to="javascript:void(0)">
              <CardActionArea style={{ "justify-content": "space-between" }}>
                <Stack direction="row">
                  <DarkModeIcon fontSize="large" />
                  <Typography component="span" alignSelf="Center">
                    Dark Mode
                  </Typography>
                </Stack>
                <Switch
                  checked={isDark}
                  onChange={handleDarkModeChange}
                  alignSelf="end"
                />
              </CardActionArea>
            </Link>
          </Card> */}
          <Card
            className={
              "card " +
              (selectedTab == SideBarMenuNames.Logout ? "active" : null)
            }
          >
            <Link to="javascript:void(0)" onClick={Logout}>
              <CardActionArea>
                <LogoutOutlinedIcon fontSize="large" />
                <Typography component="span">Logout</Typography>
              </CardActionArea>
            </Link>
          </Card>
          <Typography
            level="p"
            component="p"
            fontSize="12px"
            className="op-h mt-0 last-login-datetime"
            mt={0}
          >
            Your last login was on&nbsp;
            {Utils.IsNullOrEmptyObject(user)
              ? ""
              : Utils.GetDate(user.lastAccessed, countryInfo)}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SideBarMenuItems;
