import { getBusiness } from "../data/localSettingsActions";
import Utils from "./Utils";

export const ModuleNames = {
  Whatsapp: "Whatsapp",
  Desktop: "Desktop",
  Jobcard: "Jobcard",
  JobcardStatusIndication: "JobcardStatusIndication",
  JobcardReport: "JobcardReport",
  WebApp: "WebApp",
  PaymentLink: "PaymentLink"
};

export const CanUseWebApp = (business) => {
    if(Utils.IsNullOrEmptyObject(business))
    {
        business = getBusiness();
    }
    var module = business.enabledModules.find((m) => m.key === ModuleNames.WebApp);
    if(Utils.IsNullOrEmptyObject(module))
    {
        return false;
    }
    return true;
}

export const CanRaisePaymentLink = (business) => {
    if(Utils.IsNullOrEmptyObject(business))
    {
        business = getBusiness();
    }
    var module = business.enabledModules.find((m) => m.key === ModuleNames.PaymentLink);
    if(Utils.IsNullOrEmptyObject(module))
    {
        return false;
    }
    return true;
}

export const CanRaiseJobcard = (business) =>{
    if(Utils.IsNullOrEmptyObject(business))
    {
        business = getBusiness();
    }
    var module = business.enabledModules.find((m) => m.key === ModuleNames.Jobcard);
    if(Utils.IsNullOrEmptyObject(module))
    {
        return false;
    }
    return true;
}
