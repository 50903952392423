import Landing from "./../components/Landing";
import UnAuthorized from "./../components/UnAuthorized";
import Overview from "./../components/Overview";
import Login from "../components/Login";
import Register from "../components/Register";
import Customers from "../components/Customers";
import JobCardDetails from "../components/JobCardDetails";
import AddNewCustomer from "../components/AddNewCustomer";
import AdminUsers from "../components/AdminUsers";
import Settings from "../components/Settings";
import BusinessSetup from "../components/BusinessSetup";
import BranchSetup from "../components/BranchSetup";
import AddNewBranch from "../components/AddNewBranch";
import Visits from "../components/Visits";
import Vehicles from "../components/Vehicles";
import CheckOut from "../components/CheckOut";
import InvoiceReport from "../components/reports/InvoiceReport";
import Insurance from "../components/Insurance";
import Birthday from "../components/Birthday";
import ServiceReminder from "../components/ServiceReminder";
import Services from "../components/Services";
import Expense from "../components/Expense";
import DeliveredJobCardDetails from "../components/DeliveredJobCardDetails";
import CheckoutDetails from "../components/CheckoutDetails";
import Payment from "../components/Payment";
import UPISetup from "../components/UPISetup";
import AddNewService from "../components/AddNewService";
import AddNewUser from "../components/AddNewUser";
import Users from "../components/Users";
import AddNewConsumable from "../components/AddNewConsumable";
import AddNewSpare from "../components/AddNewSpare";
import Spares from "../components/Spares";
import Consumables from "../components/Consumables";
import StatementReport from "../components/reports/StatementReport";
import CustomerBooking from "../components/customerbooking/CustomerBooking";
import CustomerBookingError from "../components/customerbooking/CustomerBookingError";
import ExpensesReport from "../components/reports/ExpensesReport";
import ProfitAndLossReport from "../components/reports/ProfitAndLossReport";
import JobcardsReport from "../components/reports/JobcardsReport";
import EmployeeReport from "../components/reports/EmployeeReport";
import StaffReport from "../components/reports/StaffReport";
import AMCPlan from "../components/AMCPlan";
import Estimates from "../components/customer/Estimates";
import EstimateDetails from "../components/customer/EstimateDetails";
import Payments from "../components/payments/Payments";
import PaymentLinks from "../components/payments/PaymentLinks";
import AMCViewPlan from "../components/common/AMCViewPlan";

export const LandingPage = {
  Name: "Landing",
  Path: "/Landing",
  Element: <Landing />,
  IsMainPage: true,
};
export const UnAuthorizedPage = {
  Name: "UnAuthorized",
  Path: "/UnAuthorized",
  Element: <UnAuthorized />,
};
export const LoginPage = {
  Name: "Login",
  Path: "/Login",
  Element: <Login />,
};
export const RegisterPage = {
  Name: "Register",
  Path: "/Register",
  Element: <Register />,
};
export const OverviewPage = {
  Name: "Overview",
  Path: "/Overview",
  Element: <Overview />,
};
export const CustomersPage = {
  Name: "Customers",
  Path: "/Customers",
  Element: <Customers />,
};
export const JobCardDetailsPage = {
  Name: "JobCardDetails",
  Path: "/JobCardDetails",
  Element: <JobCardDetails />,
};
export const AddNewCustomerPage = {
  Name: "AddNewCustomer",
  Path: "/AddNewCustomer",
  Element: <AddNewCustomer />,
};
export const AdminUsersPage = {
  Name: "AdminUsers",
  Path: "/AdminUsers",
  Element: <AdminUsers />,
};
export const SettingsPage = {
  Name: "Settings",
  Path: "/Settings",
  Element: <Settings />,
};
export const BusinessSetupPage = {
  Name: "BusinessSetup",
  Path: "/BusinessSetup",
  Element: <BusinessSetup />,
};
export const BranchSetupPage = {
  Name: "BranchSetup",
  Path: "/BranchSetup",
  Element: <BranchSetup />,
};
export const AddNewBranchPage = {
  Name: "AddNewBranch",
  Path: "/AddNewBranch",
  Element: <AddNewBranch />,
};
export const VisitsPage = {
  Name: "Visits",
  Path: "/Visits",
  Element: <Visits />,
};
export const VehiclesPage = {
  Name: "Vehicles",
  Path: "/Vehicles",
  Element: <Vehicles />,
};
export const CheckOutPage = {
  Name: "CheckOut",
  Path: "/CheckOut",
  Element: <CheckOut />,
};
export const InvoiceReportPage = {
  Name: "InvoiceReport",
  Path: "/InvoiceReport",
  Element: <InvoiceReport />,
};
export const InsurancePage = {
  Name: "Insurance",
  Path: "/Insurance",
  Element: <Insurance />,
};
export const BirthdayPage = {
  Name: "Birthday",
  Path: "/Birthday",
  Element: <Birthday />,
};
export const ServiceReminderPage = {
  Name: "ServiceReminder",
  Path: "/ServiceReminder",
  Element: <ServiceReminder />,
};
export const ServicesPage = {
  Name: "Services",
  Path: "/Services",
  Element: <Services />,
};
export const ExpensePage = {
  Name: "Expense",
  Path: "/Expense",
  Element: <Expense />,
};
export const DeliveredJobCardDetailsPage = {
  Name: "DeliveredJobCardDetails",
  Path: "/DeliveredJobCardDetails",
  Element: <DeliveredJobCardDetails />,
};
export const CheckoutDetailsPage = {
  Name: "CheckoutDetails",
  Path: "/CheckoutDetails",
  Element: <CheckoutDetails />,
};
export const PaymentPage = {
  Name: "Payment",
  Path: "/Payment",
  Element: <Payment />,
};
export const UPISetupPage = {
  Name: "UPISetup",
  Path: "/UPISetup",
  Element: <UPISetup />,
};
export const AddNewServicePage = {
  Name: "AddNewService",
  Path: "/AddNewService",
  Element: <AddNewService />,
};
export const SparesPage = {
  Name: "Spares",
  Path: "/Spares",
  Element: <Spares />,
};
export const ConsumablesPage = {
  Name: "Consumables",
  Path: "/Consumables",
  Element: <Consumables />,
};
export const AddNewSparePage = {
  Name: "AddNewSpare",
  Path: "/AddNewSpare",
  Element: <AddNewSpare />,
};
export const AddNewConsumablePage = {
  Name: "AddNewConsumable",
  Path: "/AddNewConsumable",
  Element: <AddNewConsumable />,
};
export const UsersPage = {
  Name: "Users",
  Path: "/Users",
  Element: <Users />,
};
export const AddNewUserPage = {
  Name: "AddNewUser",
  Path: "/AddNewUser",
  Element: <AddNewUser />,
};
export const StatementReportPage = {
  Name: "StatementReport",
  Path: "/StatementReport",
  Element: <StatementReport />,
};
export const CustomerBookingPage = {
  Name: "CustomerBooking",
  Path: "/Booking",
  Element: <CustomerBooking />,
};
export const CustomerBookingErrorPage = {
  Name: "CustomerBookingError",
  Path: "/CustomerBookingError",
  Element: <CustomerBookingError />,
};
export const ExpensesReportPage = {
  Name: "ExpensesReport",
  Path: "/ExpensesReport",
  Element: <ExpensesReport />,
};
export const ProfitAndLossReportPage = {
  Name: "ProfitAndLossReport",
  Path: "/ProfitAndLossReport",
  Element: <ProfitAndLossReport />,
};
export const JobcardsReportPage = {
  Name: "JobcardsReport",
  Path: "/JobcardsReport",
  Element: <JobcardsReport />,
};
export const EmployeeReportPage = {
  Name: "EmployeeReport",
  Path: "/EmployeeReport",
  Element: <EmployeeReport />,
};
export const StaffReportPage = {
  Name: "StaffReport",
  Path: "/StaffReport",
  Element: <StaffReport />,
};
export const AMCPlanPage = {
  Name: "AMCPlan",
  Path: "/AMCPlan",
  Element: <AMCPlan />,
};
export const EstimatesPage = {
  Name: "Estimates",
  Path: "/Estimates",
  Element: <Estimates />,
};
export const EstimateDetailsPage = {
  Name: "EstimateDetails",
  Path: "/EstimateDetails",
  Element: <EstimateDetails />,
};
export const PaymentsPage = {
  Name: "Payments",
  Path: "/Payments",
  Element: <Payments />,
};
export const PaymentLinksPage = {
  Name: "PaymentLinks",
  Path: "/PaymentLinks",
  Element: <PaymentLinks />,
};
export const AMCViewPlanPage = {
  Name: "AMCViewPlan",
  Path: "/AMCViewPlan",
  Element: <AMCViewPlan />,
};
export const Pages = [
  UnAuthorizedPage,
  LandingPage,
  LoginPage,
  //RegisterPage,
  //AdminUsersPage,
  //AddNewCustomerPage,
  OverviewPage,
  CustomersPage,
  JobCardDetailsPage,
  UsersPage,
  ConsumablesPage,
  AddNewConsumablePage,
  SparesPage,
  AddNewSparePage,
  VehiclesPage,
  SettingsPage,
  BusinessSetupPage,
  BranchSetupPage,
  VisitsPage,
  CheckOutPage,
  CheckoutDetailsPage,
  InvoiceReportPage,
  InsurancePage,
  ExpensePage,
  BirthdayPage,
  ServiceReminderPage,
  UPISetupPage,
  PaymentPage,
  AddNewBranchPage,
  AddNewUserPage,
  ServicesPage,
  AddNewServicePage,
  DeliveredJobCardDetailsPage,
  StatementReportPage,
  CustomerBookingPage,
  CustomerBookingErrorPage,
  ExpensesReportPage,
  ProfitAndLossReportPage,
  JobcardsReportPage,
  EmployeeReportPage,
  StaffReportPage,
  AMCPlanPage,
  EstimatesPage,
  EstimateDetailsPage,
  PaymentsPage,
  PaymentLinksPage,
  AMCViewPlanPage,
];
