import React, { useRef, useEffect, useState } from "react";
import ServiceItem from "./ServiceItem";
import AddProduct from "./AddProduct";
import Utils from "../../core/Utils";
import Preview_Icon from "../../images/preview.png";
import Form_Icon from "../../images/form.png";
import { Box, Typography, Grid, Stack, Button, Tooltip } from "@mui/material";
import { serviceItemActionTypes } from "../reducers/serviceItem/serviceItemActionTypes";
import CustomProduct from "../CustomProduct";
import MessagePopup from "./MessagePopUp";
import { postAsync } from "../../core/serviceClient";
import { useDispatch } from "react-redux";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import { generateTemporaryVisit } from "../../helpers/provisionalInvoiceHelper";
import { BusinessCategories, VisitTypeEnum } from "../../core/Constants";
import {
  getBranch,
  getBusiness,
  getCountry,
  getUser,
} from "../../data/localSettingsActions";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import UserHelper from "../../core/UserHelper";
import { LoginPage } from "../../core/PageConstants";
import CountryHelper from "../../core/CountryHelper";

const CartComponent = ({
  serviceItems,
  onAddItem,
  handleItemAction,
  onChangeTotal,
  includePreview = true,
  customerId = "",
  vehicle = {},
  visitType = VisitTypeEnum.Checkout,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showCustomProductEntry, setShowCustomProductEntry] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [isCustomProductVisible, setIsCustomProductVisible] = useState(false);
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const dummy = useRef(null);

  useEffect(() => {
    setCustomProductButton(getBusiness());
  }, []);

  const setCustomProductButton = (currentBusiness) => {
    if (Utils.IsNullOrEmptyObject(currentBusiness)) {
      setIsCustomProductVisible(false);
      return;
    }
    if (CountryHelper.IsBusinessCountryUSA(getCountry())) {
      setIsCustomProductVisible(false);
      return;
    }
    switch (currentBusiness.category) {
      case BusinessCategories.Automobile:
      case BusinessCategories.Salon:
        setIsCustomProductVisible(true);
        break;
      default:
        setIsCustomProductVisible(false);
        break;
    }
  };

  const add = (item) => {
    onAddItem(item);
  };

  const reversedIndexOf = (ind) => {
    var length = serviceItems.length;
    if (ind === -1) {
      return -1;
    }
    return length - ind - 1;
  };

  const handleAction = (action) => {
    if (action.type === serviceItemActionTypes.Delete) {
      action.value = reversedIndexOf(action.value);
    }
    handleItemAction(action);
  };

  const clearServiceItems = () => {
    var action = {
      type: serviceItemActionTypes.Clear,
    };
    handleItemAction(action);
  };

  const clickCloseCustomProductModal = () => {
    setShowCustomProductEntry(false);
  };

  const addCustomProduct = (customItem) => {
    if (Utils.IsNullOrEmptyObject(customItem)) {
      return;
    }
    onAddItem(customItem, true);
  };

  useEffect(() => {
    setServiceItemsInReverseOrderAndCalculateTotal();
  }, [serviceItems]);

  const setServiceItemsInReverseOrderAndCalculateTotal = () => {
    var newItems = [...serviceItems];
    setItems(newItems.reverse());
    if (!Utils.IsNullOrEmptyArray(newItems)) {
      dummy.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
    if (!Utils.IsNullOrEmpty(onChangeTotal)) {
      if (Utils.IsNullOrEmptyArray(serviceItems)) {
        onChangeTotal(0);
        return;
      }
      var total = serviceItems
        .map((f) => f.TotalPriceIncludingTax)
        .reduce((a, b) => a + b);
      onChangeTotal(total);
    }
  };

  const showPreview = async () => {
    if (Utils.IsNullOrEmptyArray(serviceItems)) {
      setAlertMessage("Please add atleast one item before proceeding");
      setShowAlert(true);
      return;
    }
    var newVehicle = Utils.IsNullOrEmptyObject(vehicle) ? null : vehicle;
    var visit = generateTemporaryVisit(
      serviceItems,
      customerId,
      visitType,
      newVehicle
    );
    if (Utils.IsNullOrEmptyObject(visit)) {
      return;
    }
    setLoading(true);
    var request = {
      CountryCode: getBranch().countryCode,
      CustomerVisit: visit,
      Mask: getUser().maskCustomerDetails,
      IsPreview: true,
    };
    var result = await postAsync("Customer/InvoiceBeforePayment", request);
    setLoading(false);
    if (result.error) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlertMessage(result.errorMessage);
      setShowAlert(true);
      return;
    }
    let child = window.open("about:blank", "myChild");
    child.document.write(result.data);
    child.document.close();
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  return (
    <Box>
      <Loader open={loading} />
      <MessagePopup
        msgOpen={showAlert}
        msgText={alertMessage}
        onMsgClose={handleCloseAlert}
      />
      <CustomProduct
        open={showCustomProductEntry}
        onClose={clickCloseCustomProductModal}
        onAdd={addCustomProduct}
      />
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        fullWidth
        className="card"
        padding={1}
      >
        <Stack style={{ flex: "1" }}>
          <AddProduct onAddItem={add} />
        </Stack>
        <Stack direction="row" spacing={1} className="font-size-12">
          {includePreview && (
            <Button
              className="btn btn-secondary align-self-stretch"
              variant="contained"
              onClick={() => showPreview()}
            >
              <Box
                component="img"
                sx={{
                  width: 20,
                }}
                alt="Preview"
                src={Preview_Icon}
              />
              <span
                className="hidden-xs ml-h"
                style={{ "align-self": "center" }}
              >
                Preview Invoice
              </span>
            </Button>
          )}
          {}
          {isCustomProductVisible && (
            <Button
              className="btn btn-secondary align-self-start"
              variant="contained"
              onClick={() => setShowCustomProductEntry(!showCustomProductEntry)}
              style={{ height: "100%" }}
            >
              <Box
                component="img"
                sx={{
                  width: 26,
                }}
                alt="Add Custom"
                src={Form_Icon}
              />
              <span
                className="hidden-xs ml-h"
                style={{ "align-self": "center" }}
              >
                Add Custom Product
              </span>
            </Button>
          )}
        </Stack>
      </Stack>
      <Grid flex="1" spacing={2}>
        <Grid container xs={12} padding="0px 30px 0px 20px">
          <Grid xs={2} lg={1} className="">
            <Tooltip title="Remove All">
              <Box
                onClick={clearServiceItems}
                sx={{
                  width: 26,
                  padding: "10px 3px",
                  cursor: "pointer",
                  color: "#ff0000",
                }}
                alt="Remove All"
              >
                <DeleteForeverRoundedIcon />
              </Box>
            </Tooltip>
          </Grid>
          <Grid xs={4}>
            <Typography
              level="p"
              className="mt-1"
              component="p"
              fontSize="12px"
            >
              Description
            </Typography>
          </Grid>
          <Grid
            xs={3}
            className="text-center hidden-xs"
            style={{ "padding-right": "20px" }}
          >
            <Typography
              level="p"
              className="mt-1"
              component="p"
              fontSize="12px"
            >
              Unite Price
            </Typography>
          </Grid>
          <Grid xs={2} className="text-center hidden-xs">
            <Typography
              level="p"
              className="mt-1"
              component="p"
              fontSize="12px"
            >
              Qty
            </Typography>
          </Grid>
          <Grid xs={1} className="text-center hidden-xs">
            <Typography
              level="p"
              className="mt-1"
              component="p"
              fontSize="12px"
            >
              Tax Percent
            </Typography>
          </Grid>
          <Grid xs={1} className="text-center hidden-xs">
            <Typography
              level="p"
              className="mt-1"
              component="p"
              fontSize="12px"
            >
              Total Price
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        className="service-items"
        maxHeight={150}
        padding="0px 10px 0px 10px"
      >
        {!Utils.IsNullOrEmptyArray(items) &&
          items.map((item, i) => (
            <ServiceItem
              serviceItem={item}
              index={i}
              handleAction={handleAction}
            />
          ))}
        <div ref={dummy} />
      </Grid>
      {/* <Stack direction="row" spacing={2} justifyContent="flex-end" fullWidth>
        <Typography
          level="h4"
          className="mt-1"
          component="h4"
          fontWeight="600"
          fontSize="16px"
        >
          <small>Total:</small>
        </Typography>
        <Typography
          level="h4"
          className="mt-1"
          component="h4"
          fontWeight="600"
          fontSize="16px"
        >
          {Utils.GetCurrency(totalPrice, countryInfo)}
        </Typography>
      </Stack> */}
    </Box>
  );
};

export default CartComponent;
