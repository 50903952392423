import Utils from "../../../core/Utils";
import {
  generateErrorResult,
  getCurrentLocalDTISOString,
  throwServerOfflineError,
} from "../../../core/serviceClient";

export const get = async (url, params) => {
  let result = await fetch(Utils.GetServiceUrl() + url + "?" + params, {
    crossDomain: true,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return generateErrorResult(res);
      }
    })
    .catch((error) => {
      return throwServerOfflineError(false);
    });
  return result;
};

export const post = async(url, data) => {
    let result = await fetch(Utils.GetServiceUrl() + url, {
        crossDomain: true,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          Data: data,
          Operation: "Execute",
          CurrentLocalDT: getCurrentLocalDTISOString(),
          IsWebApp: true,
        }),
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else {
            return generateErrorResult(res);
          }
        })
        .catch((error) => {
          return throwServerOfflineError();
        });
      return result;
}
